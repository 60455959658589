import React from 'react';
import { CardMedia, Card } from "@material-ui/core";
import { masterStyles } from "../styles/GlobalStyle";

export default function BannerImage(props) {
        const master = masterStyles();
        return(
            <Card square={true}>
                <CardMedia className={`${master.bannerImage} ${props.className}`} image={props.image} component="img"/>
                {props.children}
            </Card>
        );
}