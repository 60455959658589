import React from "react";
import { Button } from "@material-ui/core";
import {Link, withRouter} from "react-router-dom";

class LoginButton extends React.Component {
    onClick = () => {
        // this.props.history.push("/client_portal");
        window.scrollTo(0,0);
    };

    render() {
        const { to, staticContext, ...rest } = this.props;
        return (
            <Link to="/client_portal" style={{textDecoration: 'none'}}>
              <Button onClick={this.onClick} color={this.props.color} className={`${this.props.className}`} variant="contained" {...rest}>{this.props.label}</Button>
            </Link>
        );
    }
}

export default withRouter(LoginButton);
