import React from "react";
import "../App.css";
import {
  Box,
  CardMedia,
  CardContent,
  Typography,
  makeStyles,
  Divider,
  Avatar,
  Container,
  Grid,
  Card,
  useMediaQuery,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { masterStyles } from "../styles/GlobalStyle";
import logo from "../images/jon.jpg";
import BannerImage from "../utils/bannerimage";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import LanguageIcon from "@material-ui/icons/Language";
import ComputerIcon from "@material-ui/icons/Computer";
import CloudIcon from "@material-ui/icons/Cloud";

const useStyles = makeStyles((theme) => ({
  surroundingText: {
    paddingTop: "1%",
    paddingBottom: "1%",
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function About({ isSolo = false }) {
  const master = masterStyles();
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.up("md"));

  const serviceData = [
    {
      avatar: <CloudIcon />,
      key: "ServiceArea4",
      serviceArea: "Cloud Solutions",
      description:
        "Do you need a serverless architecture that requires technologies such as a realtime database, authentication, and/or messaging? Our team can build that for you using the cloud solutions you know and trust, such as Amazon's AWS or Google's Firebase.",
    },
    {
      avatar: <PhoneAndroidIcon />,
      key: "ServiceArea3",
      serviceArea: "Mobile Applications",
      description:
        "Make your app ideas become a reality. We provide Android or iOS native mobile application solutions. We can even develop hybrid applications and get the best of both web and native application solutions.",
    },
    {
      avatar: <LanguageIcon />,
      key: "ServiceArea2",
      serviceArea: "Web Applications",
      description:
        "Access and use your custom applications across all of the most popular web browsers. We can provide low maintenance, high efficiency web application solutions for all of your business needs.",
    },
    {
      avatar: <ComputerIcon />,
      key: "ServiceArea1",
      serviceArea: "Custom Software",
      description:
        "Need custom software for your business or personal use? We have the solution. We provide development, integration, and maintenance solutions for new or existing software.",
    },
  ];
  return (
    <Box className={master.contrastSection}>
      {isSolo && (
        <div>
          <BannerImage image={logo} />
          <Typography
            className={`${master.headerText} ${classes.surroundingText}`}
            variant="h3"
          >
            SOME HEADER THING SHOULD GO HERE THAT I DON'T HAVE WORDS FOR
          </Typography>
          <Divider variant="middle" />
          <Typography
            className={`${master.headerText} ${classes.surroundingText}`}
            variant="body2"
          >
            {" "}
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas
            viverra quam at ante vestibulum, vitae elementum magna pharetra.
            Aliquam vel fringilla dui. Morbi pretium vehicula ipsum ac blandit.
            Vivamus ornare elementum mauris vitae semper. Phasellus in finibus
            mi. Etiam finibus felis at metus pretium venenatis. Praesent et
            lectus a ipsum viverra malesuada. Ut ut massa blandit, venenatis
            ligula vitae, aliquam purus. Aliquam tempor metus ac ex dapibus, at
            eleifend massa molestie. Donec volutpat sem ut mauris semper rutrum.
            Class aptent taciti sociosqu ad litora torquent per conubia nostra,
            per inceptos himenaeos. Nulla facilisi. Phasellus luctus tristique
            erat, sed consequat ex molestie sit amet. Nam id cursus purus, ac
            porta elit. Morbi sit amet vehicula lectus. Suspendisse malesuada,
            justo ut pellentesque commodo, lectus justo hendrerit felis, ut
            porttitor diam ligula nec felis. Phasellus sit amet dui purus. Donec
            id mi nunc. Sed suscipit venenatis magna. Nullam elit turpis,
            viverra et turpis vel, rhoncus sagittis elit. Aenean ipsum dui,
            gravida vel dolor a, cursus commodo mauris. Curabitur pulvinar, enim
            non condimentum posuere, ipsum nibh placerat ligula, non sodales
            enim dui in mauris. Sed dolor odio, malesuada vitae eleifend
            convallis, mollis quis ex. Vestibulum ultrices ac nunc ac aliquam.
            Duis tincidunt, massa id egestas tempus, nisl eros iaculis nisl, ac
            aliquet lorem dui at justo. Vivamus nisl orci, porttitor et
            sollicitudin at, sagittis ut tellus. Ut et commodo risus, et tempor
            massa. Mauris vel velit sit amet ipsum posuere molestie. Curabitur a
            ultrices tellus. Praesent a mi et enim euismod volutpat. Phasellus
            vel fermentum turpis. Sed accumsan libero ac sem vulputate, sed
            rhoncus ipsum laoreet.
          </Typography>
          <Divider variant="middle" />
        </div>
      )}
      <Typography
        className={`${master.headerText} ${classes.surroundingText}`}
        variant="h4"
      >
        Our Services
      </Typography>
      <Container
        maxWidth="md"
        className={master.contrastSection}
        style={{ paddingBottom: "2%" }}
      >
        <Grid
          container
          alignContent={mobile ? "stretch" : "center"}
          justify="space-between"
          direction={mobile ? "row" : "column"}
        >
          {serviceData.map((tile) => (
            <Grid item key={tile.key} xs={mobile ? 6 : 8}>
              <Card className={master.contrastSection}>
                <CardMedia>
                  <Avatar className={classes.avatar} variant="rounded">
                    {tile.avatar}
                  </Avatar>
                </CardMedia>
                <CardContent>
                  <Typography variant="h6">{tile.serviceArea}</Typography>
                  <Typography variant="body2">{tile.description}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
