import React from "react";
import { MemoryRouter as Router } from 'react-router';
import {Grid, Paper, Typography, Link, Breadcrumbs, makeStyles} from "@material-ui/core";
import {Home} from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
    links: {
      color: theme.palette.secondary.main,
    },
    crumbs:{
      color: theme.palette.secondary.main,
    },
    paper: {
      backgroundColor: theme.palette.contrast.main,
    },
    portal: {
      paddingLeft: 0,
      fontSize: "1rem", 
      fontFamily: [
        "Roboto", 
        "Helvetica",
        "Arial",
        "sans-serif"
      ].join(","),
      fontWeight: "400",
      lineHeight: "1.5",
      letterSpacing: "0.00938em",
      textTransform: "none",
      backgroundColor: theme.palette.contrast.main,
      '&:hover': {
        backgroundColor: theme.palette.contrast.main,
        textDecoration: "underline",
      }
    }
}));

function Footer(){
  const classes = useStyles();
  return (
    <div className="Footer">
      <Paper className={classes.paper} square>
        <Grid container direction="column" alignItems="center" alignContent="center">
          <Grid item>
            <Breadcrumbs className={classes.crumbs}>
              <Router>
                <Link className={classes.links} component={Link} href="/">
                  <Home />
                </Link>
              </Router>
              <Router>
                <Link className={classes.links} component={Link} color="inherit" variant="body1" href="/#about">
                  <Typography variant="body1">About</Typography>  
                </Link>
              </Router>
              <Router>
                <Link className={classes.links} component={Link} color="inherit" variant="body1" href="/#team">
                  <Typography variant="body1">Team</Typography>
                </Link>
              </Router>
              <Router>
                <Link className={classes.links} component={Link} color="inherit" variant="body1" href="/client_portal">
                  <Typography variant="body1">Client Portal</Typography>
                </Link>
              </Router>
            </Breadcrumbs>
          </Grid>
          <Grid item>
            <Typography color="secondary" variant="body1" style={{paddingBottom: "3px"}}>(412) 347-5020</Typography>
          </Grid>
          <Grid item>
            <Typography color="secondary" variant="body1">solutions@jcspgh.com</Typography>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

export default Footer;