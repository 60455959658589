import React from "react";
import { Button } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';

class AboutButton extends React.Component {
  onClick = () => {
    // this.props.history.push("/#about");
  };
  render() {
    const { to, staticContext, ...rest } = this.props;
    return (
      <Link to="/#about" style={{textDecoration: 'none'}}>
        <Button onClick={this.onClick} {...rest}>{this.props.label}</Button>
      </Link>
    );
  }
}
export default withRouter(AboutButton);
