import React, { useState } from "react";
import {
  TextField,
  Grid,
  Button,
  Zoom,
  makeStyles,
  InputAdornment,
  Icon,
  Typography,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { masterStyles } from "../styles/GlobalStyle";
import PersonIcon from "@material-ui/icons/Person";
import LockIcon from "@material-ui/icons/Lock";
import { firebaseLogin } from "../auth/auth";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: "white",
  },
  input: {
    backgroundColor: "white",
  },
  text: {
    // color: "#1c1b1b"
    color: theme.palette.primary.main,
  },

  //radial-gradient(circle, rgba(0,0,0,1) 18%, rgba(255,255,255,1) 100%)
}));

export default function ClientPortalLogin(props) {
  const master = masterStyles();
  const classes = useStyles();

  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [hasAttemptedLogin, setHasAttemptedLogin] = useState(false);

  const [userIsFocused, setUserIsFocused] = useState(false);
  const [passIsFocused, setPassIsFocused] = useState(false);

  //   const [isUsernameValid, setIsUsernameValid] = useState(true);
  //   const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);

  //   const validateUsername = (text) => {
  //     setIsUsernameValid(false);
  //   };

  //   const validatePassword = (text) => {
  //     setIsPasswordValid(false);
  //   };

  //   const validateText = (text) => {
  //     return text.length > 0;
  //   };

  const login = async (event) => {
    // validatePassword();
    // validateUsername();
    event.preventDefault();

    const user = await firebaseLogin(username, password);
    if (user != null) {
      setIsSuccess(true);
    } else {
      setIsSuccess(false);
    }
    setHasAttemptedLogin(true);
  };

  const fieldData = [
    {
      id: "user-name",
      label: "Username",
      changeSetFunction: setUserName,
      value: username,
      isFocus: userIsFocused,
      changeFocusFunction: setUserIsFocused,
      //   changeSetIsFunction: setIsUsernameValid,
      //   error: !isUsernameValid,
      icon: <PersonIcon />,
      hide: false,
    },
    {
      id: "password",
      label: "Password",
      changeSetFunction: setPassword,
      value: password,
      isFocus: passIsFocused,
      changeFocusFunction: setPassIsFocused,
      //   changeSetIsFunction: setIsPasswordValid,
      //   error: !isPasswordValid,
      icon: <LockIcon />,
      hide: true,
    },
  ];

  return (
    <div style={{ padding: "20px" }}>
      <form onSubmit={login}>
        <Grid
          container
          alignContent="space-around"
          direction="column"
          wrap="nowrap"
          spacing={1}
        >
          <Grid item container alignItems="center" justify="center">
            <Grid item>
              <Typography color="primary"> LOGIN </Typography>
            </Grid>
          </Grid>
          {fieldData.map((tile) => (
            <Grid item key={tile.id}>
              <Grid container alignItems="center" justify="center">
                <Grid item xs={8}>
                  <TextField
                    // style={{width: "90%"}}
                    className={master.textField}
                    required
                    key={tile.label}
                    id={tile.id}
                    label={tile.isFocus || tile.value !== "" ? "" : tile.label}
                    variant="outlined"
                    type={tile.hide ? "password" : ""}
                    InputLabelProps={{
                      // style: {color: "#607D8B"},
                      shrink: false,
                    }}
                    InputProps={{
                      className: classes.input,
                      endAdornment: (
                        <InputAdornment>
                          <Icon>{tile.icon}</Icon>
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      tile.changeSetFunction(e.target.value);
                      // tile.changeSetIsFunction(validateText(e.target.value));
                    }}
                    onFocus={(e) => {
                      tile.changeSetFunction(e.target.value);
                      tile.changeFocusFunction(true);
                      // tile.changeSetIsFunction(validateText(e.target.value));
                    }}
                    onBlur={(e) => {
                      tile.changeFocusFunction(false);
                    }}
                    error={tile.error}
                    margin="dense"
                  />
                </Grid>
              </Grid>
            </Grid>
          ))}
          <Grid item container justify="center">
            <Grid item xs={8}>
              <Zoom in={!isSuccess && hasAttemptedLogin} unmountOnExit>
                <Alert severity="error">
                  Error: Username or password is incorrect.{" "}
                </Alert>
              </Zoom>
            </Grid>
          </Grid>
          <Grid item container justify="center">
            <Grid item xs={8}>
              <Button
                elevation={0}
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
              >
                {" "}
                Login{" "}
              </Button>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              alignItems="center"
              justify="space-around"
              wrap="nowrap"
            >
              <Grid item>
                <Button color="primary" onClick={props.onForgotClick}>
                  Forgot Password?
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
