import React, { useState } from "react";
import {
  Paper,
  Container,
  Box,
  makeStyles
} from "@material-ui/core";
import ClientPortalLogin from "../forms/clientportallogin"
import ForgotPassword from "../forms/forgotpassword"
import stock from "../images/loginstock.jpg";

const useStyles = makeStyles(theme => ({
    paper: {
      backgroundColor: "white"
    },
    input: {
      backgroundColor: "white"
    },
    text: {
      color: theme.palette.primary.main
    }
  }));

export default function LoginPage() {
    const classes = useStyles();

    const [forgotPassword, setForgotPassword] = useState(false);

    let portal;
    if(!forgotPassword)
    {
        portal = <ClientPortalLogin onForgotClick={() => setForgotPassword(true)}/>
    }
    else
    {
        portal = <ForgotPassword onClick={() => setForgotPassword(false)}/>
    }
    return (
        <Box height="39vmax" width="100%" py="25vh"
          style={
            {
              // radial-gradient(circle, rgba(0,0,0,1) 18%, rgba(255,255,255,1) 100%)
              background: `radial-gradient(circle, rgba(0,0,0,0.7), rgba(255,255,255,.1)), url(${stock})`, 
              backgroundAttachment: "fixed", 
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat", 
              backgroundPosition: "50%"
            }}>
            <Container maxWidth="sm"
            >
                {/* background: 'radial-gradient(circle, rgba(0,0,0,0.1), rgba(0,0,0,0))' */}
                <Paper className={classes.paper} style={{background: 'rgba(255,255,255,0.1)'}} elevation={0}>
                    {portal}
                </Paper>
            </Container>
        </Box>
    );
}