import React from "react";
import "../App.css";
import {
  Box,
  CardMedia,
  Typography,
  GridList,
  GridListTile,
  Grid,
  makeStyles,
  CardContent,
  Card,
  useMediaQuery,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import logo from "../images/jon.jpg";
import jon from "../images/jon.jpg";
import zach from "../images/zach.jpg";
import josh from "../images/josh.jpg";
import { masterStyles } from "../styles/GlobalStyle";
import BannerImage from "../utils/bannerimage";

const useStyles = makeStyles((theme) => ({
  media: {
    borderRadius: "50%",
    width: "75%",
    height: "auto",
    paddingTop: "4%",
    [theme.breakpoints.down("sm")]: {
      width: "40%",
    },
  },
  intro_banner: {
    paddingTop: "1%",
  },
  card: {
    background: theme.palette.test.main,
  },
  tile: {
    marginBottom: "25px",
    border: "2px solid #36454F",
  },
}));

function Team({ isSolo = false }) {
  const classes = useStyles();
  const master = masterStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.up("md"));

  const tileData = [
    {
      key: "JoshSubheader",
      image: josh,
      name: "Josh Plumley",
      title: "CEO",
      description:
        "If you talk to anyone that knows me, one of the first things that you will learn is that I am a builder at heart. Whether it's renovating a new bathroom, building a piece of furniture, or developing a  mobile app, creating things has always been a passion of mine. The ability to build beautiful furniture from seemingly insignificant scraps of wood or transform numerous lines of obscure text into a functional application is an experience that just never gets old for me.",
    },
    {
      key: "ZachSubheader",
      image: zach,
      name: "Zachary Hull",
      title: "CFO",
      description:
        "Jigsaw, sudoku, nonograms. I love solving just about any kind of puzzle. At times, I can get so engrossed by working out a puzzle, I lose track of time until I can find a solution. That's how I approach software problems, whether it's tracking down a bug or creating a new feature. Each variable, each line of code, each function is a step or a clue towards finding the optimal solution. There is no better feeling than finally putting that last piece together to reveal something amazing.",
    },
    {
      key: "JonSubheader",
      image: jon,
      name: "Jon Bolles",
      title: "CTO",
      description:
        "I've always been a nerd at heart. Ever since I was a kid, I've always had a fascination for computers and electronics. Sometimes that fascination led me to breaking, and then fixing, my family's computer after trying to make it run faster. Other times I ended up creating an application that helped someone be more productive. In either case, I've always had a passion for creating, fixing, and learning more about technology that people find useful.",
    },
  ];
  return (
    <Box>
      {isSolo && <BannerImage image={logo} />}
      <Box>
        <Typography
          className={classes.intro_banner}
          align="center"
          variant="h4"
          component="h5"
          color="textPrimary"
          gutterBottom
        >
          Meet Our Team
        </Typography>
      </Box>
      <Box margin="auto" width={"75vw"} color="text.Primary">
        <GridList cellHeight="auto" cols={mobile ? 3 : 1} spacing={20}>
          {tileData.map((tile) => (
            <GridListTile key={tile.key}>
              <Card className={`${master.card} ${classes.tile}`} elevation={5}>
                <Grid container item alignItems="center" justify="center">
                  <CardMedia
                    className={classes.media}
                    component="img"
                    image={tile.image}
                  />
                </Grid>
                <CardContent>
                  <Typography variant={"h4"}>{tile.name}</Typography>
                  <Typography variant={"h5"}>{tile.title}</Typography>
                  <Typography> {tile.description} </Typography>
                </CardContent>
              </Card>
            </GridListTile>
          ))}
        </GridList>
      </Box>
    </Box>
  );
}

export default Team;
