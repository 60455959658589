import React from "react";
import { Fab, Hidden, useMediaQuery, Snackbar } from "@material-ui/core";
import Alert from "../utils/alert";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import LetsChatForm from "../forms/LetsChatForm";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginLeft: theme.spacing(1),
  },
  location: {
    [theme.breakpoints.down("md")]: {
      position: "fixed",
      top: "auto",
      right: 5,
      bottom: 15,
      left: "auto",
    },
    [theme.breakpoints.up("md")]: {
      position: "fixed",
      top: "auto",
      right: 40,
      bottom: 20,
      left: "auto",
    },
  },
}));

export default function LetsChatButton(props) {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.up("md"));

  const [emailFormOpen, setEmailFormOpen] = React.useState(false);
  const [successOpen, setSuccessOpen] = React.useState(false);
  const handleClickOpenForm = () => {
    setEmailFormOpen(true);
  };

  const handleSuccessClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessOpen(false);
  };

  const handleCloseForm = (event, reason) => {
    if (reason === "success") {
      setSuccessOpen(true);
    }
    setEmailFormOpen(false);
  };

  return (
    <div>
      <Fab
        className={`${props.className} ${classes.location}`}
        style={{
          zIndex: 20,
        }}
        color={props.color}
        variant={mobile ? "extended" : "round"}
        onClick={handleClickOpenForm}
        size={mobile ? "large" : "medium"}
      >
        <MailOutlineIcon />
        <Hidden smDown>
          <div className={classes.icon}>{props.children}</div>
        </Hidden>
      </Fab>
      <LetsChatForm
        emailFormOpen={emailFormOpen}
        handleCloseForm={handleCloseForm}
      ></LetsChatForm>
      <Snackbar
        open={successOpen}
        autoHideDuration={10000}
        onClose={handleSuccessClose}
      >
        <Alert onClose={handleSuccessClose} severity="success">
          Thank you for reaching out to JCS! We will get back to you shortly to
          discuss your exciting project with you!
        </Alert>
      </Snackbar>
    </div>
  );
}
