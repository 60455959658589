import {makeStyles} from '@material-ui/core/styles';

const rawMasterStyles = theme => ({
    bannerImage: {
        height: "500px",
        width: "100",
        // borderRadius: "10px",
    },
    headerText: {
        textAlign: "center",
        paddingRight: theme.spacing(6),
        paddingLeft: theme.spacing(6),
    },
    card: {
        position: 'relative',
        width: "auto",
        height: "auto",
        [theme.breakpoints.up('lg')]:{
            paddingRight: theme.spacing(4),
            paddingLeft: theme.spacing(4),
        },
    },
    textColor:{
        color: "white",
    },
    center: {
        position: "absolute",
        textAlign: "center",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    },
    verticalSpacing: {
        paddingTop: '4%',
        paddingBottom: "4%",
    },
    horizontalSpacing: {
        paddingRight: theme.spacing(4),
        paddingLeft: theme.spacing(4),
    },
    media: {
        borderRadius: "10px"
    },
    textField: {
        paddingBottom: "5% !important",
        width: "100%"
    },
    paragraphText: {
        paddingBottom: "5%",
        width: "50vw",
        [theme.breakpoints.up('md')]:{
            width: "30vw",
        }
    },
    alternateBackground: {
        backgroundColor: theme.palette.primary.main,
    },
    contrastSection: {
        backgroundColor: theme.palette.contrast.main,
        color: theme.palette.secondary.main,
    },
});

const masterStyles = makeStyles(rawMasterStyles);

export { rawMasterStyles, masterStyles };