// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import firebase from "firebase/compat/app";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_JCS_WEBSITE_API_KEY,
  authDomain: process.env.REACT_APP_JCS_WEBSITE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_JCS_WEBSITE_DATABASE_URL,
  projectId: process.env.REACT_APP_JCS_WEBSITE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_JCS_WEBSITE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_JCS_WEBSITE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_JCS_WEBSITE_APP_ID,
  measurementId: process.env.REACT_APP_JCS_WEBSITE_MEASUREMENT_ID,
};
firebase.initializeApp(firebaseConfig);
export default firebase;
