import React, { useState } from "react";
import "../App.css";
import {
  Box,
  CardActionArea,
  CardMedia,
  Card,
  Grid,
  Typography,
} from "@material-ui/core";
import fireJonOne from "../images/FireJon_1.png";
import fireJonTwo from "../images/FireJon_2.jpg";
import fireJonThree from "../images/FireJon_3.jpg";
import { masterStyles } from "../styles/GlobalStyle";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import * as emailjs from "emailjs-com";

const useStyles = makeStyles((theme) => ({
  title: {
    top: "50%",
    left: "50%",
    padding: "33px",
  },
  image: {
    paddingBottom: "30px",
  },
}));

const sendEmail = (jonNumber) => {
  const templateParams = {
    to_email: process.env.REACT_APP_RECIPIENT_EMAIL,
    from_email: process.env.REACT_APP_FROM_EMAIL,
    firstName: `Jon_${jonNumber}`,
    lastName: "Bolles",
    subject: `Jon_${jonNumber}_gets_fired`,
    message: `Jon_${jonNumber}_gets_fired`,
  };

  emailjs
    .send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_NOTFOUND_TEMPLATE_ID,
      templateParams,
      process.env.REACT_APP_EMAILJS_PUBLIC_KEY
    )
    .then(
      (response) => {
        if (response.status === 200) {
          console.log("SUCCESS SENDING EMAIL!", response.status, response.text);
        } else {
          console.log("ERROR SENDING EMAIL", response.status, response.text);
        }
      },
      (error) => {
        console.log("FAILED...", error.text);
      }
    );
};

function NotFound() {
  const master = masterStyles();
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [clickable, setClickable] = useState(true);
  const [jonNumber, setJonNumber] = useState(1);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const clickJonOne = () => {
    if (clickable === true) {
      setOpen(true);
      setJonNumber(1);
      sendEmail(1);
      setClickable(false);
    }
  };
  const clickJonTwo = () => {
    if (clickable === true) {
      setOpen(true);
      setJonNumber(2);
      sendEmail(2);
      setClickable(false);
    }
  };
  const clickJonThree = () => {
    if (clickable === true) {
      setOpen(true);
      setJonNumber(3);
      sendEmail(3);
      setClickable(false);
    }
  };

  const message = `Thank you for firing Jon #${jonNumber}! HR has been sent an email and Jon will be
  escorted out of the building. Have a nice day!`;

  return (
    <Box height="85vh">
      <Typography id="fire-jon-title" className={classes.title}>
        This page doesn't exist. Please choose which Jon to fire.
      </Typography>
      <Grid container item direction="row" xs={12}>
        <Grid item xs={4}>
          <Card className={`${master.card} ${master.horizontalSpacing}`}>
            <CardActionArea>
              <CardMedia
                className={classes.image}
                image={fireJonOne}
                component="img"
                onClick={clickJonOne}
              />
            </CardActionArea>
          </Card>
        </Grid>

        <Grid item xs={4}>
          <Card className={`${master.card} ${master.horizontalSpacing}`}>
            <CardActionArea>
              <CardMedia
                className={classes.image}
                image={fireJonTwo}
                component="img"
                onClick={clickJonTwo}
              />
            </CardActionArea>
          </Card>
        </Grid>

        <Grid item xs={4}>
          <Card className={`${master.card} ${master.horizontalSpacing}`}>
            <CardActionArea>
              <CardMedia
                className={classes.image}
                image={fireJonThree}
                component="img"
                onClick={clickJonThree}
              />
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
      <Snackbar open={open} autoHideDuration={10000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default NotFound;
