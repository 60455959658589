import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import * as emailjs from "emailjs-com";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "../utils/alert";
import { masterStyles } from "../styles/GlobalStyle";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    display: "flex",
    flexDirection: "column",
  },
  dialogPaper: {
    width: "100vw",
  },
  sendButton: {
    color: theme.palette.forms.buttonText,
    marginRight: "16px",
    marginBottom: "16px",
  },
  cancelButton: {
    marginBottom: "16px",
  },
}));

export default function LetsChatForm({ emailFormOpen, handleCloseForm }) {
  const master = masterStyles();
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isFirstNameValid, setIsFirstNameValid] = useState(true);
  const [isLastNameValid, setIsLastNameValid] = useState(true);
  const [isSubjectValid, setIsSubjectValid] = useState(true);
  const [isMessageValid, setIsMessageValid] = useState(true);
  const [errorOpen, setErrorOpen] = React.useState(false);
  const [sentEmail, setSentEmail] = React.useState(false);
  const [isEmailSending, setIsEmailSending] = useState(false);

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorOpen(false);
  };

  const handleEmailSendClick = () => {
    sendEmail();
  };

  const sanitizeAndSetEmail = (email) => {
    email = email.trim();

    setEmail(email);
  };

  const handleFormClose = () => {
    // Handle cleanup of internal states and then refer to outside state
    setEmail("");
    setFirstName("");
    setLastName("");
    setSubject("");
    setMessage("");
    setIsEmailValid(true);
    setIsFirstNameValid(true);
    setIsLastNameValid(true);
    setIsSubjectValid(true);
    setIsMessageValid(true);
    setErrorOpen(false);
    setSentEmail(false);

    // return emailFormOpen;
    // handleCloseForm();
  };

  const sendEmail = () => {
    const templateParams = {
      to_email: process.env.REACT_APP_RECIPIENT_EMAIL, // Change to your recipient email
      email: email,
      firstName: firstName,
      lastName: lastName,
      subject: subject,
      message: message,
    };
    setIsEmailSending(true);

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then((response) => {
        if (response.status === 200) {
          console.log("SUCCESS SENDING EMAIL!", response.status, response.text);
          setSentEmail(true);
          handleCloseForm({}, "success");
        } else {
          setErrorOpen(true);
          setSentEmail(false);
          console.log("ERROR SENDING EMAIL", response.status, response.text);
        }
      })
      .catch((error) => {
        console.error("ERROR SENDING EMAIL", error);
      })
      .finally(() => {
        setIsEmailSending(false);
      });
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const isFormValid = () => {
    return (
      validateEmail(email) &&
      validateText(firstName) &&
      validateText(lastName) &&
      validateText(subject) &&
      validateText(message)
    );
  };

  const validateText = (text) => {
    return text.length > 0;
  };

  const fieldData = [
    {
      id: "first-name",
      label: "First Name",
      changeSetFunction: setFirstName,
      changeSetIsFunction: setIsFirstNameValid,
      error: !isFirstNameValid,
      validate: validateText,
      value: firstName,
    },
    {
      id: "last-name",
      label: "Last Name",
      changeSetFunction: setLastName,
      changeSetIsFunction: setIsLastNameValid,
      error: !isLastNameValid,
      validate: validateText,
      value: lastName,
    },
    {
      id: "subject",
      label: "Subject",
      changeSetFunction: setSubject,
      changeSetIsFunction: setIsSubjectValid,
      error: !isSubjectValid,
      validate: validateText,
      value: subject,
    },
    {
      id: "email-address",
      label: "Email Address",
      changeSetFunction: sanitizeAndSetEmail,
      changeSetIsFunction: setIsEmailValid,
      error: !isEmailValid,
      validate: validateEmail,
      value: email,
    },
  ];

  return (
    <div>
      <Dialog
        open={emailFormOpen}
        onClose={handleCloseForm}
        onExit={handleFormClose}
        PaperProps={{ className: classes.dialogPaper }}
      >
        <DialogTitle id="form-dialog-title" key="form-dialog-title">
          Let's Chat
        </DialogTitle>
        <DialogContent>
          {fieldData.map((tile) => (
            <Grid item key={tile.id}>
              <TextField
                className={master.textField}
                required
                key={tile.label}
                id={tile.id}
                label={tile.label}
                color="primary"
                variant="filled"
                onChange={(e) => {
                  tile.changeSetFunction(e.target.value);
                  tile.changeSetIsFunction(tile.validate(tile.value));
                }}
                onFocus={(e) => {
                  tile.changeSetFunction(e.target.value);
                  tile.changeSetIsFunction(tile.validate(tile.value));
                }}
                error={tile.error}
                helperText={tile.error ? "Required" : ""}
              />
            </Grid>
          ))}
          <Grid item>
            <TextField
              className={master.textField}
              id="outlined-textarea"
              key="Message"
              label="Message"
              placeholder="What can we help you with?"
              rows="6"
              multiline
              required
              variant="filled"
              onChange={(e) => {
                setMessage(e.target.value);
                setIsMessageValid(validateText(e.target.value));
              }}
              onFocus={(e) => {
                setMessage(e.target.value);
                setIsMessageValid(validateText(e.target.value));
              }}
              error={!isMessageValid}
              helperText={!isMessageValid ? "Required" : ""}
            />
          </Grid>
          <Grid item></Grid>
        </DialogContent>
        <DialogActions>
          <Button className={classes.cancelButton} onClick={handleCloseForm}>
            Cancel
          </Button>
          <Button
            className={classes.sendButton}
            disabled={!isFormValid() || sentEmail || isEmailSending}
            variant="contained"
            color="primary"
            onClick={handleEmailSendClick}
          >
            Send
          </Button>
          <Snackbar
            open={errorOpen}
            autoHideDuration={10000}
            onClose={handleErrorClose}
          >
            <Alert onClose={handleErrorClose} severity="error">
              It looks like something went wrong. Please try again. If the
              problem persists, please reach out to solutions@jcspgh.com
              directly.
            </Alert>
          </Snackbar>
        </DialogActions>
      </Dialog>
    </div>
  );
}
