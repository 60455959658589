import Bar from "./utils/bar";
import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/home";
import Footer from "./utils/footer";
// import 'core-js/es/map';
// import 'core-js/es/set';
// import Team from "./pages/Team";
// import Portfolio from "./pages/portfolio";
// import LetsChatForm from "./forms/LetsChatForm";
// import About from "./pages/about";
import NotFound from "./pages/notFound";
import masterTheme from "./styles/GlobalTheme";
import { ThemeProvider } from "@material-ui/core";
// import ClientPortalLogin from "./pages/clientportallogin";
// import ForgotPassword from "./pages/forgotpassword";
import LoginPage from "./pages/loginpage";
import LetsChatButton from "./buttons/letsChatButton";
import UnsupportedBrowser from "./pages/unsupportedBrowser";
import isInternetExplorerDevice from "./utils/deviceCheck";

class App extends React.Component {
  render() {
    return isInternetExplorerDevice() ? (
      <UnsupportedBrowser />
    ) : (
      <ThemeProvider theme={masterTheme()}>
        <LetsChatButton color="primary" variant="contained">
          {" "}
          Let's Chat{" "}
        </LetsChatButton>
        <Router>
          <Bar />
          <Switch>
            <Route
              path="/"
              exact
              render={(props) => <Home {...props} multipleHeaders={false} />}
            />
            <Route
              path="/home"
              render={(props) => <Home {...props} multipleHeaders={false} />}
            />
            {/* <Route path="/client_portal/recovery" render={(props) => <ForgotPassword {...props}/>}/> */}
            <Route
              path="/client_portal"
              render={(props) => <LoginPage {...props} />}
            />
            <Route component={NotFound} />
          </Switch>
          <Footer />
        </Router>
      </ThemeProvider>
    );
  }
}

export default App;
