import firebase from "./firebaseConfig";
import "firebase/compat/auth";

export const firebaseLogin = async (userEmail, password) => {
  const userCredential = await firebase
    .auth()
    .signInWithEmailAndPassword(userEmail, password)
    .catch(function (error) {
      // Handle Errors here.[]
      // var errorCode = error.code;
      // var errorMessage = error.message;
      return null;
    });
  return userCredential?.user;
};

export const firebasePasswordReset = async (userEmail) => {
  let success = false;
  await firebase
    .auth()
    .sendPasswordResetEmail(userEmail)
    .then(function () {
      success = true;
    })
    .catch(function (error) {
      success = false;
    });

  return success;
};

firebase.auth().onAuthStateChanged(function (user) {
  if (user) {
    // User is signed in.
    // var displayName = user.displayName;
    // var email = user.email;
    // var emailVerified = user.emailVerified;
    // var photoURL = user.photoURL;
    // var isAnonymous = user.isAnonymous;
    // var uid = user.uid;
    // var providerData = user.providerData;
    // ...Store this to a user var and import where we want to use it.
  } else {
    // User is signed out.
    // ...
  }
});
