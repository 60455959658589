import React, { useState } from "react";
import {
  TextField,
  Grid,
  Button,
  Zoom,
  makeStyles,
  InputAdornment,
  Icon,
  Typography,
  Hidden
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { masterStyles } from "../styles/GlobalStyle";
import PersonIcon from '@material-ui/icons/Person';
import { firebasePasswordReset } from "../auth/auth";

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: "white"
  },
  input: {
    backgroundColor: "white"
  },
  text: {
    color: theme.palette.primary.main
  },
  back:{
    backgroundColor: theme.palette.contrast.main
  }
}));


export default function ForgotPassword(props) {
  const master = masterStyles();
  const classes = useStyles();

  const [username, setUserName] = useState("");
  const [userIsFocused, setUserIsFocused] = useState(false);

  const [hasAttemptedReset, setHasAttemptedReset] = useState(false);

  //   const [isUsernameValid, setIsUsernameValid] = useState(true);
  //   const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);

  //   const validateUsername = (text) => {
  //     setIsUsernameValid(false);
  //   };

  //   const validatePassword = (text) => {
  //     setIsPasswordValid(false);
  //   };

  //   const validateText = (text) => {
  //     return text.length > 0;
  //   };

  const recover = async (event) => {
    // validatePassword();
    // validateUsername();
    event.preventDefault();

    const success = await firebasePasswordReset(username);
    setIsSuccess(success);
    setHasAttemptedReset(true);
  };

    const fieldData = [
        {
          id: "user-name",
          label: "Username",
          changeSetFunction: setUserName,
          value: username,
          isFocus: userIsFocused,
          changeFocusFunction: setUserIsFocused,
        //   changeSetIsFunction: setIsUsernameValid,
        //   error: !isUsernameValid,
          icon: <PersonIcon/>
        },
    ];

    return (
      <div style={{padding:"20px"}}>
        <form onSubmit={recover}>
          <Grid container alignContent="space-around" direction="column" wrap="nowrap" spacing={1}>
            <Grid item container alignItems="center" justify="center">
              <Grid item>
                <Typography color="primary"> FORGOT PASSWORD </Typography>
              </Grid>
            </Grid>
              {fieldData.map((tile) => (
                  <Grid item key={tile.id}>
                    <Grid container alignItems="center" justify="center"> 
                          <Grid item xs={8}> 
                              <TextField
                                  // style={{width: "90%"}}
                                  className={master.textField}
                                  required
                                  key={tile.label}
                                  id={tile.id}
                                  label={tile.isFocus || (tile.value !== "") ? "" : tile.label}
                                  color="primary"
                                  variant="outlined"
                                  InputProps={{
                                    className: classes.input,
                                    endAdornment: 
                                    (<InputAdornment>
                                    <Icon>
                                      {tile.icon}
                                      </Icon>
                                    </InputAdornment>),
                                  }}
                                  onChange={(e) => {
                                      tile.changeSetFunction(e.target.value);
                                      // tile.changeSetIsFunction(validateText(e.target.value));
                                  }}
                                  onFocus={(e) => {
                                      tile.changeSetFunction(e.target.value);
                                      tile.changeFocusFunction(true);
                                      // tile.changeSetIsFunction(validateText(e.target.value));
                                  }}
                                  onBlur={(e) => {
                                    tile.changeFocusFunction(false);
                                  }}
                                  error={tile.error}
                                  margin="dense"
                              />
                          </Grid>
                      </Grid>
                  </Grid>
              ))}
            <Grid item container justify="center">
                <Grid item xs={8}>
                  <Zoom in={!isSuccess && hasAttemptedReset} unmountOnExit>
                      <Alert severity="error">Error: Username is incorrect. </Alert>
                  </Zoom>
                  <Zoom in={isSuccess && hasAttemptedReset} unmountOnExit>
                      <Alert severity="success">Password reset email sent. </Alert>
                  </Zoom>
                  </Grid>
              </Grid>
              <Hidden xsDown>
                <Grid item container justify="center">
                  <Grid item xs={4}>
                    <Button className={classes.back} elevation={0} variant="contained" color="primary" onClick={props.onClick}> Go Back </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container justify="flex-end">
                      <Grid item>
                        <Button elevation={0} variant="contained" color="primary" type="submit"> Recover </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Hidden>
              <Hidden smUp>
                <Grid item container justify="center">
                  <Grid item xs={8}>
                    <Button elevation={0} variant="contained" color="primary" type="submit" fullWidth> Recover </Button>
                  </Grid>
                </Grid>
                <Grid item container justify="center">
                  <Grid item xs={8}>
                        <Button className={classes.back} elevation={0} variant="contained" color="primary" onClick={props.onClick} fullWidth> Go Back </Button>
                  </Grid>
                </Grid>
              </Hidden>
          </Grid>
        </form>
      </div>
    );

}
