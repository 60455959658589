import {createMuiTheme, responsiveFontSizes} from '@material-ui/core/styles';

function masterTheme() {
    let  masterTheme = createMuiTheme({
        palette: {
            primary: {
                main: '#bd7347',
            },
            secondary: {
                main: '#f6f6f6',
            },
            forms: {
                buttonText: "white",
                buttonBackground: "#1c1b1b",
            },
            contrast: {
                main: '#607D8B',
            },
            test: {
                main: 'linear-gradient(to left top, #bd7347, #d8d8c0)',
            }
        },
        props: {
            MuiCard: {
                elevation: 0,
            },
        }
    });

    return (responsiveFontSizes(masterTheme, {factor: 2, breakpoints: ['xs', 'sm', 'md', 'lg', 'xl']}));
}

export default masterTheme;