import React from "react";
import { Button } from "@material-ui/core";
import { withRouter } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';

class TeamButton extends React.Component {
  onClick = () => {
    this.props.history.push('/#team');
    // window.scrollTo(0,0);
  }
  render() {
    return (
      <Link to="/#team" style={{textDecoration: 'none'}}>
        <Button onClick={this.onClick}>Team</Button>
      </Link>
    );
  }
}
export default withRouter(TeamButton);
