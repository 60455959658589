import React from "react";
import { Card, CardMedia, Link } from "@material-ui/core";
import { MemoryRouter as Router } from "react-router";
import { withStyles } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import JCSLight from "../images/JCSLogo-RGB-Web.png";

const styles = (theme) => ({
  media: {
    width: "150px",
    height: "auto",
    paddingLeft: "10px",
    paddingTop: "10px",
  },
});

class HomeButton extends React.Component {
  onClick = () => {
    this.props.history.push("/");
    window.scrollTo(0,0);
  };
  render() {
    const { classes } = this.props;
    const image = JCSLight;
    return (
      <Router>
        <Link component={Link} href="/">
          <Card elevation={0}>
            <CardMedia
              className={classes.media}
              component="img"
              image={image}
            />
          </Card>
        </Link>
      </Router>
    );
  }
}
export default withStyles(styles, { withTheme: true })(withRouter(HomeButton));
