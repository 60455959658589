import React from "react";
import homeImage from "../images/pittsburgh.jpeg";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Grid, Container, Paper } from "@material-ui/core";
import { CardContent, CardMedia, Card, Hidden } from "@material-ui/core";
import { masterStyles } from "../styles/GlobalStyle";
import Team from "./Team";
import AboutButton from "../buttons/aboutButton";
import About from "./about";
import BannerImage from "../utils/bannerimage";
import codeStock from "../images/codeStock.jpg";
import stock from "../images/stock.jpg";

const useStyles = makeStyles((theme) => ({
  media: {
    filter: 'brightness(0.9)',
  },
  overlay: {
    color: "black",
    border: "1px solid #344560",
    borderRadius: "5px",
    backgroundColor: "#EFF2F6",
  },
  root: {
    display: "flex",
    backgroundColor: "transparent"
  },
  image: {
    width: "50vw",
    height: "20vw",
    // boxShadow: "10px 5px 5px white inset",
    opacity: "0.95",
    [theme.breakpoints.up('md')]:{
      width: "30vw",
      height: "30vw",
    },
    [theme.breakpoints.up('lg')]:{
      width: "20vw",
      height: "20vw",
    },
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  divider: {
    paddingTop: "2%",
    paddingBottom: "2%",
  },
  bodyText: {
    // fontFamily: "\"Open Sans\", \"sans-serif\"",
    // fontSize: "1.1rem",
    color: "#212121"
  }
}));

function Home({hidePageLinks=false, multipleHeaders=false}) {
  const classes = useStyles();
  const master = masterStyles();

  return (
      <Box>
        <BannerImage className={classes.media} image={homeImage} />
        <Box className={master.contrastSection}>
          <Container maxWidth="lg">
            <Paper elevation={0} className={master.contrastSection}>
              <Typography className={`${master.headerText} ${master.verticalSpacing} ${master.contrastSection}`} variant="h3">
                Building Software Solutions Together
              </Typography>
            </Paper>
          </Container>
        </Box>
        {/* <Container maxWidth="xl">
          <Divider variant="middle" />
        </Container> */}
        <Container maxWidth="xl" style={
          {
            background: `linear-gradient(rgba(255,255,255,.85), rgba(255,255,255,.85)), url(${stock})`, 
            backgroundAttachment: "fixed", 
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat", 
            backgroundPosition: "25%"
          }}>
          <Grid container justify="center" alignItems="center" direction="column">
            <Grid item>
              <Card className={`${classes.root} ${master.verticalSpacing}`}>
                {/* <div className={classes.details}> */}
                <Grid container alignItems="center"> 
                  <Grid item> 
                  <CardContent className={classes.content}>
                    <Typography className={master.paragraphText} variant="h4">
                      Who we are
                    </Typography>
                    <Hidden mdUp>
                      <CardMedia
                        className={classes.image}
                        image={codeStock}
                        component="img"
                      />
                    </Hidden>
                    <Typography className={`${master.paragraphText} ${classes.bodyText}`} variant="body1">
                    JCS was founded with the ambition to make a positive impact throughout the Pittsburgh area. The mission: help individuals and businesses during all stages of life reach their technical goals by developing their ideas into working solutions.
                    <br/><br/>
                    And much like Pittsburgh over the last two decades, JCS embraces new and innovating challenges. Each member of the team has a natural curiosity and a desire to learn up-and-coming technologies while providing high-quality products and services.
                    </Typography>
                    {hidePageLinks && <AboutButton
                      label="About"
                      color="primary"
                      variant="contained"
                    /> }
                  </CardContent>
                  </Grid>
                {/* </div> */}
                <Grid item> 
                <Hidden smDown>
                  <CardMedia
                    className={classes.image}
                    image={codeStock}
                    component="img"
                  />
                </Hidden>
                </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Container>
        {/* <Container className={`${classes.divider} ${classes.contrastSection}`} maxWidth="xl">
          <Divider variant="middle" />
        </Container> */}
        <div id="about">
          <About isSolo={multipleHeaders}/>
        </div>
        {/* <Container className={`${classes.divider} ${classes.contrastSection}`} maxWidth="xl">
          <Divider variant="middle" />
        </Container> */}
        <div id="team">
          <Team isSolo={multipleHeaders}/>
        </div>
      </Box>
  );
}

export default Home;
