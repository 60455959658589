import React from "react";
import { AppBar, Toolbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Hidden, Menu, MenuItem, IconButton } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import TeamButton from "../buttons/teamButton";
import HomeButton from "../buttons/homeButton";
// import PortfolioButton from "./buttons/portfolio";
import AboutButton from "../buttons/aboutButton";
import LoginButton from "../buttons/loginbutton";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Slide from "@material-ui/core/Slide";

function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}
const useStyles = makeStyles((theme) => ({
  toolbar: {
    background: "white",
    minHeight: 50,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(4),
  },
  container: {
    display: "flex",
  },
  menu: {
    justifyContent: "center",
  },
  chat: {
    width: "200px",
  },
}));

function Bar() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyles();
  return (
    <HideOnScroll>
      <AppBar position={"sticky"} style={{ opacity: "0.95" }}>
        <Toolbar className={classes.toolbar}>
          <Grid
            alignItems="center"
            justify="space-between"
            container
            wrap="nowrap"
          >
            <Grid item xs>
              <HomeButton />
            </Grid>

            <Grid item xs={10}>
              <Grid container justify="flex-end">
                <Hidden smDown>
                  <Grid item>
                    <AboutButton label="About" />
                  </Grid>
                  {/* <Grid item>
                  <PortfolioButton label="Portfolio"/>
                </Grid> */}
                  <Grid item>
                    <TeamButton label="Team" />
                  </Grid>
                  <Grid item>
                    <LoginButton
                      className={classes.chat}
                      color="primary"
                      variant="contained"
                      label="Client Portal"
                    />
                  </Grid>
                </Hidden>
                <Hidden mdUp>
                  <Grid item>
                    <IconButton aria-label="menu" onClick={handleClick}>
                      <MenuIcon color="primary" />
                    </IconButton>
                    <Menu
                      id="menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      onClick={handleClose}
                    >
                      <MenuItem className={classes.menu}>
                        {" "}
                        <AboutButton label="About" />{" "}
                      </MenuItem>
                      {/* <MenuItem className={classes.menu}> <PortfolioButton label="Portfolio" /> </MenuItem> */}
                      <MenuItem className={classes.menu}>
                        {" "}
                        <TeamButton label="Team" />{" "}
                      </MenuItem>
                      <MenuItem className={classes.menu}>
                        {" "}
                        <LoginButton
                          className={classes.chat}
                          color="primary"
                          variant="contained"
                          label="Client Portal"
                        />{" "}
                      </MenuItem>
                    </Menu>
                  </Grid>
                </Hidden>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
}

export default Bar;
