import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
  unsupportedBrowser: {
    padding: "5px",
    backgroundColor: "#fceba0",
    text: "#000",
  },
});

const UnsupportedBrowser = () => {
  const classes = useStyles();

  return (
    <div className={classes.unsupportedBrowser}>
      <b>Please note that we no longer support Internet Explorer</b>
      <p>
        We only support the recent versions of major browsers like Chrome,
        Firefox, Safari, and Edge. Sorry for the inconvenience.
      </p>
    </div>
  );
};

export default UnsupportedBrowser;
